import cookies from 'js-cookie'
import API from '../../api'

export const account = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    role: null,
    data: {
      id: null,
      name: null,
      email: null,
      phone: null,
      avatar: null,
      credits: null,
      tokens: null,
      is_active: null,
      settings: {
        default_crop: null,
        background_id: null,
        background_type: null,
        logo_id: null,
        logo_position: null,
        licence_plate_id: null,
        cutting: null,
        plate_blur: null,
        plate_image: null,
        polishing: null,
        rim_polishing: null,
        reflective_floor: null,
        preset_id: null,
        preset: {
          id: null,
          name: null,
        },
        background: {
          id: null,
          url: null,
        },
        logo: {
          id: null,
          url: null,
        },
        licence_plate: {
          id: null,
          url: null,
        }
      },
      company: null,
    }
  }),
  mutations: {
    setAccount(state, payload) {
      state.data = payload
      state.role = payload.role
    }
  },

  getters: {
    getAccount: state => {
      return state.data
    }
  },

  actions: {
    async requestBase({ commit }) {
      const res = await API.auth('get', 'me')
      commit('setAccount', res.data)
      cookies.set('user', JSON.stringify(res.data))
    },
    async sendBase({ state, commit }, { body, toast, t, bodyType }) {
      state.isLoading = true
      try {
        const res = await API.companyUsers('post', 'companyProfile', body, bodyType )
        commit('setAccount', res.data)
        cookies.set('user', JSON.stringify(res.data))
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 })
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
        state.error = error
      } finally {
        state.isLoading = false
      }
    },
  }
}
