<template>
  <component :is="layout" v-if="$route.name">
    <router-view />
  </component>
  <Toast />
  <ConfirmPopup />
</template>

<script>

import * as layouts from './layouts'
import Toast from 'primevue/toast';
import ConfirmPopup from 'primevue/confirmpopup';

document.title = 'Caiman'

export default {
  components: {
    Toast,
    ConfirmPopup,
  },
  computed: {
    layout() {
      return layouts[this.$route.meta.layout] || layouts.Default
    }
  },
}
</script>