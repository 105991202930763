<template>
  <div :class="col">
    <div :class="$style.box">
      <svg width="258" height="203" :class="$style.substrate" viewBox="0 0 258 203" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="258" height="203" rx="10" fill="#F3F6F8"/></svg>
      <img :src="`${url}`" alt="" :class="$style.img">
      <button v-if="callback" @click="callback" :class="$style.action"><Icon name="trash" /></button>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue';
import Icon from './Icon.vue'

export default {
  components: {
    Icon
  },
  props: {
    url: String,
    col: String,
    callback: Function
  },
  setup() {

    return {

    }
  }
}
</script>

<style lang="scss" module>
  .box {
    border-radius: $radius;
    position: relative;
  }

  .substrate {
    width: 100%;
    height: 100%;
    display: block;
  }

  .img {
    border-radius: $radius;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .action {
    @include anim(fill);
    position: absolute;
    top: 16px;
    right: 16px;
    background-color: $secondary_gray;
    border-radius: $radius-small;
    width: 42px;
    height: 42px;
    fill: $text_color_light_gray;

    &:hover {
      fill: $text_color_black
    }
  }
</style>