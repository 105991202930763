import API from '../../api'
import { saveAs } from 'file-saver';
import { errorHandling } from '../../utils'

function processingResponses(res) {
  if (!res.vehicle_name) {
    res.vehicle_name = `${res.brand} ${res.model}`
  }
  res.preset.items.forEach(el => {
    const photo = res.photos.find(({ preset_item_id }) => preset_item_id === el.id)
    if (photo) {
      el.photo_id = photo.id
      el.photo_url = photo.url
      el.processed_url = photo.processed_url
      el.in_process = photo.in_process
    }
  })
  return res
}

function download(filename, data) {
  const blob = new Blob([data], { type: 'application/zip' })
  saveAs(blob, filename );
}

export const car = {
  namespaced: true,

  state: () => ({
    isLoading: true,
    isError: false,
    carID: null,
    isTestRequesting: false,
    photoUploading: {},
    data: {
      photos: [],
      settings: {},
      preset: {
        items: []
      }
    },
    error: null,
  }),
  mutations: {
    setIsTestRequesting(state, payload) {
      state.isTestRequesting = payload
    },
    resetData(state) {
      state.carID = null
    }
  },

  getters: {
    getData: state => {
      return state.data[state.carID] || {
        photos: [],
        settings: {},
        preset: {
          items: []
        }}
    },
    withProcessedPhoto: state => {
      return !!state.data.preset.items.find(({ in_process }) => in_process === 1)
    },
  },

  actions: {
    async reqBase({ state }, { car, isTestRequest }) {
      if (state.carID !== car) {
        state.data = {
          photos: [],
          settings: {},
          preset: {
            items: []
          }
        }
      }
      state.carID = car
      if (!isTestRequest) {
        state.isLoading = true
      }
      try {
        const res = await API.cars('get', 'car', { car })

        state.data = processingResponses(res.data)
        state.isError = false
      } catch (error) {
        state.isError = true
        state.error = error
      } finally {
        state.isLoading = false
      }
    },
    async sendBase({ state }, { params, toast, t }) {
      state.isLoading = true
      const body = {
        car: params.car,
        vin: params.vin,
        vehicle_name: params.vehicle_name,
        brand: params.brand,
        model: params.model,
        body_type: params.body_type,
        fuel_type: params.fuel_type,
        hp: params.hp,
        ccm: params.ccm,
        default_crop: params.settings.default_crop,
        background_id: params.settings.background_id,
        banner_id: params.settings.banner_id,
        background_type: params.settings.background_type,
        logo_id: params.settings.logo_id,
        logo_position: params.settings.logo_position,
        licence_plate_id: params.settings.licence_plate_id,
        preset_id: params.settings.preset_id,
        cutting: params.settings.cutting,
        plate_blur: params.settings.plate_blur,
        plate_image: params.settings.plate_image,
        polishing: params.settings.polishing,
        rim_polishing: params.settings.rim_polishing,
        reflective_floor: params.settings.reflective_floor,
      }

      try {
        const res = await API.cars('post', 'car', body)
        state.data = processingResponses(res.data)
        state.isError = false
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.update_car'), life: 3000 })
      } catch (error) {
        state.isError = true
        state.error = error
        const { title, message } = errorHandling(error, t)
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      } finally {
        state.isLoading = false
      }
    },

    async reqUploadPhoto({ dispatch, state }, { body, toast, t }) {
      try {
        state.photoUploading[body.preset_item] = true
        await API.cars('post', 'photoUpload', body, 'formData')
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.upload_car'), life: 3000 })
        state.photoUploading[body.preset_item] = false
        dispatch('reqBase', { car: body.car })
      } catch (error) {
        state.photoUploading[body.preset_item] = false
        console.log(error);
      }
    },

    async reqDownloadPhoto({}, { body, toast, t }) {
      try {
        const res = await API.cars('post', 'photoDownload',  body )
        saveAs(res.data.link)
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    },
    async reqProcessPhoto({state}, { body, toast, t }) {
      try {
        await API.cars('post', 'photoProcess', body)
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.photo_processing'), life: 3000 })
        state.data.preset.items.map((item) => {
          if (item.photo_id === body.photo) {
            item.in_process = 1
          }
        })
      } catch (error) {
        const { title, message } = errorHandling(error, t)
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    },
    async reqDelete({}, { car, callback }) {
      try {
        await API.cars('delete', 'delete', { car })
        callback()
      } catch (error) {
      }
    },
  }
}