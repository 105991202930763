<template>
  <div :class="$style.core" class="flex align-items-center" @click="toggle">
    <Avatar size="medium" :img="$store.state.account.data.avatar" />
    <span class="ml-3">{{name}}</span>
    <Icon name="chevron_down" className="_icon-gray" />
  </div>
  <OverlayPanel ref="panel">
    <button
      class="flex align-items-center px-3 py-2"
      v-for="(item, key) in items"
      :key="key"
      @click="item.action">
      <Icon :name="item.icon" className="_icon-gray" />
      <span v-t="item.label" class="ml-3"></span>
    </button>
  </OverlayPanel>
</template>

<script>

import cookies from 'js-cookie'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import OverlayPanel  from 'primevue/overlaypanel';
import { Icon } from '../../components/ui'
import { Avatar } from '../../components/ui'


import API from '../../api'

export default {
  components: {
    Avatar,
    Icon,
    OverlayPanel,
  },
  setup() {
    const store = useStore()
    const router = useRouter()

    const items = [
      {
        label: 'logout',
        icon: 'log_out',
        action: () => {
          panel.value.hide()
          API.auth('post', 'logout')
          cookies.remove('token', '')
          cookies.remove('user', '')
          cookies.remove('no-refresh', '')
          router.push({ name: 'Login' })
        }
      },
    ]
    const panel = ref();
    const toggle = (e) => {
      panel.value.toggle(e)
    };
    return {
      panel,
      items,
      toggle,
      name: computed(() => store.state.account.data.name)
    }
  }
}
</script>

<style lang="scss" module>
  .core {
    position: relative;
    cursor: pointer;
  }
</style>
