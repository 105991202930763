<template>
  <div :class="$style.item" v-for="item in menu" :key="item.url || item.to.name">
    <router-link
      v-if="item.to"
      :to="item.to"
      :class="$style.link"
      :active-class="$style.active"
    >
      <Icon :name="item.icon" />
      <span :class="$style.name" v-t="item.label"></span>
    </router-link>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue';
import { Icon } from '../../components/ui'
import { menu } from '../../router'

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore()
    return {
      menu: computed(() => menu[store.state.account.role])
    }
  }
}
</script>

<style lang="scss" module>
  .item {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .link {
    @include anim(color, background-color);
    display: flex;
    align-items: center;
    font-size: 18px;
    color: $text_color_white_op_1;
    width: 100%;
    height: 48px;
    padding-left: 30px;
    font-size: 16px;
    position: relative;
    fill: currentColor;

    &:before {
      @include anim(transform);
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: $primary_red;
      transform: scaleY(0);
    }


    &.active {
      &:before {
        transform: scaleY(1);
      }
    }

    &:hover,
    &.active {
      color: $text_color_white;
      background-color: $text_color_white_op_2;
    }
  }

  .name {
    padding-left: 20px;
  }
</style>
