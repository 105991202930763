<template>
  <div class="p-5 flex flex-column align-items-center justify-content-center w-full" :class="isOverlay && $style.overlay">
    <ProgressSpinner />
  </div>
</template>

<script>

import ProgressSpinner from 'primevue/progressspinner';
export default {
  components: {
    ProgressSpinner
  },
  props: {
    isOverlay: Boolean
  },
  setup() {

    return {}
  }
}
</script>

<style lang="scss" module>
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, 0.5);
    z-index: 2;
  }
</style>