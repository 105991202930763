import { saveAs } from 'file-saver';
import API from '../../api'
import { errorHandling } from '../../utils'

export const photos = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    isError: false,
    data: {
      photos: [],
      datasets: []
    },
  }),
  mutations: {
    setAccount(state, payload) {
      state.data = payload
    }
  },

  getters: {

  },

  actions: {
    async reqBase({ state }, params) {
      try {
        state.isLoading = true
        const res = await API.photos('get', 'section', params)
        state.data = res.data
        state.isLoading = false
        state.isError = false
        state.error = null
      } catch (error) {
        state.isLoading = false
        state.isError = true
        state.error = error
      }
    },
    async reqUploadPhoto({ state, dispatch }, { body, toast, t }) {
      try {
        state.isLoading = true
        const res = await API.photos('post', 'photoUpload', body, 'formData')
        state.isLoading = false
        state.isError = false
        state.error = null
        if (!res.message) {
          dispatch('reqBase', { sectionID: body.section_id })
          toast.add({ severity: 'success', summary: t('success'), life: 3000 })
        }
      } catch (error) {
        state.isLoading = false
        state.isError = true
        state.error = error

        const { title, message } = errorHandling(error, t)
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    },
    async reqDatasetUpload({ state }, { body, toast, t }) {
      try {
        state.isLoading = true
        const res = await API.photos('post', 'datasetUploadNeuro', body, 'formData')
        state.isLoading = false
        state.isError = false
        state.error = null
        if (!res.message) {
          state.data.datasets.push(res.data)
          toast.add({ severity: 'success', summary: t('success'), life: 3000 })
        }
      } catch (error) {
        state.isLoading = false
        state.isError = true
        state.error = error

        const { title, message } = errorHandling(error, t)
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    },
    async reqDownloadPhoto({ }, { body, toast, t }) {
      try {
        const res = await API.photos('post', 'photoDownload', body)
        saveAs(res.data.link)
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    },
    async reqDatasetDownload({ }, { sectionID, toast, t }) {
      try {
        const res = await API.photos('get', 'datasetDownloadNeuro', { sectionID })
        saveAs(res.data.link)
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    },
    async reqMarkProcessedPhoto({ state }, { body, toast, t }) {
      console.log(body);
      try {
        await API.photos('post', 'markProcessed', body)
        state.data.processed_photos_count = Object.values(body).filter(item => item).length
        toast.add({ severity: 'success', summary: t('success'), life: 3000 });
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    },
    async reqDeleteAlbum({ }, { body, toast, t, router }) {
      try {
        await API.photos('delete', 'deleteSection', body)
        toast.add({ severity: 'success', summary: t('success'), life: 3000 });
        router.push({ name: 'PhotoAlbums' })
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    },
  }
}