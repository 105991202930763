<template>
  <svg width="99" height="99" viewBox="0 0 99 99" :class="$style.noImg" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M49.5 9.75C43.8439 9.75 38.4676 10.9304 33.6006 13.0569C32.8414 13.3886 31.9571 13.042 31.6255 12.2829C31.2938 11.5238 31.6403 10.6395 32.3994 10.3078C37.6381 8.01897 43.4228 6.75 49.5 6.75C73.1102 6.75 92.25 25.8898 92.25 49.5C92.25 55.5772 90.981 61.3619 88.6922 66.6006C88.3605 67.3597 87.4762 67.7062 86.7171 67.3745C85.958 67.0429 85.6114 66.1586 85.9431 65.3994C88.0696 60.5325 89.25 55.156 89.25 49.5C89.25 27.5467 71.4533 9.75 49.5 9.75ZM19.2862 19.2651C19.8736 18.6892 20.8152 18.6939 21.3969 19.2756L79.7244 77.6031C80.3079 78.1866 80.3105 79.1319 79.7302 79.7186C71.9773 87.5563 61.3809 92.25 49.5 92.25C25.8898 92.25 6.75 73.1102 6.75 49.5C6.75 37.5565 11.3624 27.0329 19.2862 19.2651ZM20.3453 22.4666C13.6332 29.5558 9.75 38.9016 9.75 49.5C9.75 71.4533 27.5467 89.25 49.5 89.25C60.0252 89.25 69.4372 85.3025 76.5285 78.6498L20.3453 22.4666Z" fill="#E4E4E4"/>
  </svg>

</template>

<style lang="scss" module>
  .noImg {
    width: 100px;
    height: 100px;
  }
</style>