<template>
  <div>
    <div class="flex">
      <Contenteditable tag="h1" class="_heading-1" :contenteditable="true" v-model="state.vehicle_name" :noNL="true" :noHTML="true" @returned="save" />
      <button :class="$style.action" @click="save" class="ml-3"><Icon name="save" /></button>
    </div>
    <div :class="$style.vin" class="flex">
      <Contenteditable tag="p" class="_label" :contenteditable="false" v-model="state.vin" :noNL="true" :noHTML="true" @returned="save" />
      <!-- <button :class="$style.action" @click="save" class="ml-3"><Icon name="edit" /></button> -->
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import Contenteditable from 'vue-contenteditable'

import { Icon } from '../../components/ui'

export default {
  components: {
    Contenteditable,
    Icon
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const { t } = useI18n()
    const toast = useToast()
    const state = computed(() => store.state.car.data)

    function save() {
      state.value.car = route.params.vin
      store.dispatch('car/sendBase', { params: state.value, toast, t  })
    }
    return {
      state,
      save,
    }
  }
}
</script>

<style lang="scss" module>
  .vin {
    color: $text_color_gray;
    margin-bottom: -21px;
  }

  .action {
    @include anim(fill);
    fill: $text_color_light_gray;

    &:hover {
      fill: $text_color_black
    }
  }
</style>