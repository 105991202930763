export const errorHandling = (error, t) => {
  if (!error || !error.response) {
    return {
      code: '',
      title: t('something_went_wrong'),
      message: '',
      fields: []
    }
  }

  if (error.response) {
    return {
      code: error.response.status,
      title: error.response.data.message,
      message: error.response.data.errors,
      fields: Object.keys(error.response.data?.errors || {})
    }
  }
}