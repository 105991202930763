<template>
  <Tag :severity="status.type">
    <span v-t="status.label" />
  </Tag>
</template>

<script>

import Tag from 'primevue/tag';
export default {
  components: {
    Tag
  },
  props: {
    isActive: [Number, Boolean]
  },
  setup(props) {
    const status = props.isActive ? {
      type: 'success',
      label: 'status_bool.active'
    } : {
      type: 'danger',
      label: 'status_bool.blocked'
    }
    return {
      status
    }
  }
}
</script>

<style lang="scss" module>
</style>