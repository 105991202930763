import API from '../../api'

export const companyUsers = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    isError: false,
    data: null,
    meta: {}
  }),
  mutations: {
    setAccount(state, payload) {
      state.data = payload
    }
  },

  getters: {

  },

  actions: {
    async reqBase({state}, params) {
      try {
        state.isLoading = true
        const res = await API.companyUsers('get', 'users', params)
        state.data = res.data.data
        state.meta = res.data.meta
        state.isLoading = false
        state.isError = false
        state.error = null
      } catch (error) {
        state.isLoading = false
        state.isError = true
        state.error = error
      }
    }
  }
}