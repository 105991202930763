const getUrlWithParamsConfig = (method, config, endpoint, params) => {
  const uri = config.endpoints[endpoint]

  // eslint-disable-next-line prefer-const
  let body = {}

  const apiConfigUrl = {
    ...config.server,
    ...uri,
    query: {
      ...uri.query
    }
  }

  const query = {
    ...params
  }

  const pathname = Object.keys(query).reduce((acc, val) => {
    if (acc.indexOf(`{${val}}`) !== -1) {
      const result = acc.replace(`{${val}}`, query[val])
      delete query[val]
      return result
    }
    return acc
  }, apiConfigUrl.pathname )

  apiConfigUrl.pathnamePrefix = (config.pathnamePrefix ? config.pathnamePrefix : '')
  apiConfigUrl.pathnameWithoutPrefix = pathname
  apiConfigUrl.pathname = apiConfigUrl.pathnamePrefix + pathname

  if (method === 'get' || method === 'delete') {
    apiConfigUrl.query = {
      ...apiConfigUrl.query,
      ...query
    }
  } else {
    body = {
      ...query
    }
  }

  return {
    apiConfigUrl,
    body
  }
}

export default getUrlWithParamsConfig
