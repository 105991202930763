import API from '../../api'

export const cars = {
  namespaced: true,

  state: () => ({
    isLoading: true,
    isError: false,
    data: null,
    meta: {},
    error: null,
  }),
  mutations: {
  },

  getters: {

  },

  actions: {
    async reqBase({state}, params) {
      state.isLoading = true
      try {
        const res = await API.cars('get', 'cars', params)
        state.isLoading = false
        state.isError = false
        state.data = res.data.data
        state.meta = res.data.meta
      } catch (error) {
        state.isLoading = false
        state.isError = true
        state.error = error
      }
    }
  }
}