export const header = {
  namespaced: true,

  state: () => ({
    title: null,
    titleComponent: null,
    tabs: null
  }),
  mutations: {
    setTabs(state, payload) {
      state.tabs = payload
    },
    setTitle(state, payload) {
      state.title = payload
    },
    setTitleComponent(state, payload) {
      state.titleComponent = payload
    },
  },
}