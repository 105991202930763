<template>
  <div class="p-5 flex flex-column align-items-center justify-content-center">
    <div class="_heading-2" :class="$style.empty" v-t="'no_results'"></div>
  </div>
</template>

<script>
export default {
  components: {
  },
  setup() {

    return {}
  }
}
</script>

<style lang="scss" module>
  .empty {
    color: $text_color_light_gray;
  }
</style>