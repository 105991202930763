import API from '../../api'
import { errorHandling } from '../../utils'

export const albums = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    isError: false,
    data: null,
  }),
  mutations: {
    setAccount(state, payload) {
      state.data = payload
    }
  },

  getters: {

  },

  actions: {
    async reqBase({ state }, params) {
      try {
        state.isLoading = true
        const res = await API.photos('get', 'sections', params)
        state.data = res.data
        state.isLoading = false
        state.isError = false
        state.error = null
      } catch (error) {
        state.isLoading = false
        state.isError = true
        state.error = error
      }
    },
    async sendBase({ state, dispatch }, { body, callback, toast, t }) {
      try {
        state.isLoading = true
        const res = await API.photos('post', 'sections', body)
        state.isLoading = false
        state.isError = false
        state.error = null
        if (!res.message) {
          if (body.parent_id) {
            dispatch('reqBase')
          } else {
            state.data.push(res.data)
          }
          toast.add({ severity: 'success', summary: t('success'), life: 3000 })
        }
        setTimeout(() => {
          callback()
        }, 0);
      } catch (error) {
        state.isLoading = false
        state.isError = true
        state.error = error

        const { title, message } = errorHandling(error, t)
        toast.add({ severity: 'error', summary: title, detail: message, life: 3000 });
      }
    },
  }
}