<template>
  <div :class="$style.header">
    <Toolbar :class="$style.container">
      <template #left>
        <router-link :to="{name: $route.meta.backTo}" class="p-button p-button-outlined p-button-gray mr-4 p-button-only-icon" v-if="$route.meta.backTo">
          <Icon name="arrow_left" />
        </router-link>
        <h1 class="_heading-1" v-if="$route.meta.title" v-t="$route.meta.title"></h1>
        <h1 class="_heading-1" v-else-if="$store.state.header.title">{{$store.state.header.title}}</h1>
        <CarTitle v-else-if="$store.state.header.titleComponent === 'CarTitle'" />
      </template>

      <template #right>
        <User />
        <Language />
      </template>
    </Toolbar>
    <div class="_container">
      <TabMenu :model="$store.state.header.tabs" :exact="false">
        <template #item="{item}">
          <router-link :to="item.to" v-t="item.label" class="p-menuitem-link"></router-link>
        </template>
      </TabMenu>
    </div>
  </div>
</template>

<script>
import Toolbar from 'primevue/toolbar'
import TabMenu from 'primevue/tabmenu'
import CarTitle from './CarTitle'

import { Icon } from '../../components/ui'

import { default as User } from './User.vue'
import { default as Language } from './Language.vue'

export default {
  components: {
    TabMenu,
    Toolbar,
    Icon,
    User,
    Language,
    CarTitle
  },
  setup() {
    return {  }
  },
}
</script>


<style lang="scss" module>
  .header {
    position: sticky;
    top: 0;
    z-index: 5;
    background-color: #fff;
    border-bottom: 1px solid $text_color_line_gray;
  }
  .container {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }
</style>