import API from '../../api'

export const companyUser = {
  namespaced: true,

  state: () => ({
    isLoading: false,
    isError: false,
    isSubmiting: false,
    data: {
      settings: {},
      company: {
        chief: {}
      }
    }
  }),
  mutations: {
    setData(state, payload) {
      state.data = payload
    }
  },

  getters: {
    getData: state => {
      return state.data
    }
  },

  actions: {
    async reqBase({ state }, { user }) {
      state.isLoading = true
      try {
        const res = await API.companyUsers('get', 'user', { user })
        state.data = res.data
        state.isLoading = false
        state.error = false
      } catch (error) {
        state.isLoading = false
        state.error = error
      }
    },
    async sendBase({ state, commit }, { body, toast, t }) {
      state.isSubmiting = true
      try {
        const res = await API.companyUsers('post', 'user', body, 'formData')
        state.data = res.data
        state.isLoading = false
        state.isSubmiting = false
        state.error = false
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 })
      } catch (error) {
        state.isLoading = false
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
        state.error = error
      }
    },
    async sendNew({ state }, { body, reset, toast, t }) {
      state.isSubmiting = true
      try {
        const res = await API.companyUsers('post', 'users', body)
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 })
        state.data = res.data
        state.isLoading = false
        state.isSubmiting = false
        state.error = false
        reset()
      } catch (error) {
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
        state.isLoading = false
        state.isSubmiting = false
        state.error = error
      }
    },
    async reqToggleBlock({ state }) {
      state.isLoading = true
      try {
        const res = await API.companyUsers('post', 'toggleBlock', { user: state.data.id })
        state.isLoading = false
        state.data.is_active = res.data.is_active
      } catch (error) {
        state.isLoading = false
      }
    },
    async reqDelete({ state }) {
      try {
        const res = await API.adminCompanies('delete', 'toggleBlock', { user: state.data.id })
        state.data.is_active = res.data.is_active
      } catch (error) {

      }
    },
    async reqAddCredits({ state }, { amount }) {
      try {
        await API.adminCompanies('post', 'addCredits', { user: state.data.id, amount })
        state.data.credits = amount
      } catch (error) {

      }
    },
  }
}