import API from '../../api'

export const company = {
  namespaced: true,

  state: () => ({
    isLoading: true,
    isError: false,
    isSubmiting: false,
    data: {},
  }),
  mutations: {
    setData(state, payload) {
      state.data = payload
      state.isLoading = false
      state.isError = false
    }
  },

  getters: {
    getData: state => {
      return state.data
    }
  },

  actions: {
    async reqBase({ state, commit }, { company }) {
      state.isLoading = true
      try {
        const res = await API.adminCompanies('get', 'company', { company })
        const data = {
          ...res.data,
          name: res.data.chief.name,
          email: res.data.chief.email,
          phone: res.data.chief.phone,
        }
        delete data.chief
        state.data = data
        state.error = false
        state.isLoading = false
      } catch (error) {
        state.error = true
        state.isLoading = false
      }
    },
    async sendBase({ state }, { body, toast, t }) {
      state.isSubmiting = true
      try {
        const res = await API.adminCompanies('post', 'company', { ...body, company: body.id })
        const data = {
          ...res.data,
          name: res.data.chief.name,
          email: res.data.chief.email,
          phone: res.data.chief.phone,
        }
        delete data.chief
        state.isLoading = false
        state.isSubmiting = false
        state.data = data
        state.error = false
        toast.add({ severity: 'success', summary: t('success'), detail: t('toast.success_profile'), life: 3000 })
      } catch (error) {
        state.error = true
        state.isLoading = false
        state.isSubmiting = false
        toast.add({ severity: 'error', summary: t('error'), detail: error.message, life: 3000 });
      }
    },
    async reqToggleBlock({ state }) {
      try {
        const res = await API.adminCompanies('post', 'toggleBlock', { company: state.data.id })
        state.data.is_active = res.data.is_active
      } catch (error) {
        console.error(error);
      }
    },
    async reqDelete({ state }) {
      try {
        await API.adminCompanies('delete', 'toggleBlock', { company: state.data.id })
      } catch (error) {
        console.error(error);
      }
    },
    async reqAddCredits({ state }, { amount }) {
      try {
        await API.adminCompanies('post', 'addCredits', { company: state.data.id, amount })
        state.data.credits = res.data.credits
      } catch (error) {
        console.error(error);
      }
    },
  }
}