import API from '../../api'

export const settings = {
  namespaced: true,

  state: () => ({
    isSubmiting: false,
    presets: {
      isLoading: false,
      data: []
    },
    bg: {
      isLoading: false,
      data: []
    },
    logos: {
      isLoading: false,
      data: []
    },
    banners: {
      isLoading: false,
      data: []
    },
  }),
  getters: {
    separatedBackgrounds: state => {
      return {
        system: state.bg.data.filter(({ company_id }) => !company_id),
        own: state.bg.data.filter(({ company_id }) => company_id),
      }
    }
  },

  actions: {
    async reqBase({ state }, { model, endpoint }) {
      state[model].isLoading = true
      try {
        const res = await API.companyUsers('get', endpoint)
        state[model].isLoading = false
        state[model].data = res.data
      } catch (error) {
        console.error(error);
      }
    },
    async reqPresets({ state }) {
      state.presets.isLoading = true
      try {
        const res = await API.settings('get', 'presets')
        state.presets.isLoading = false
        state.presets.data = res.data
      } catch (error) {
        console.error(error);
      }
    },
    async submitBase({ state }, { model, endpoint, body }) {
      state.isSubmiting = true
      state[model].isLoading = true
      try {
        const res = await API.companyUsers('post', endpoint, body, 'formData')
        state[model].isLoading = false
        state[model].data.push(res.data)
        state.isSubmiting = false
      } catch (error) {
        state[model].isLoading = true
        state.isSubmiting = false
        console.error(error);
      }
    },
    async deleteBase({ state }, { model, endpoint, background }) {
      state[model].isLoading = true
      try {
        await API.companyUsers('delete', endpoint, { background })
        state[model].isLoading = false
        state[model].data = state[model].data.filter(({ id }) => id !== background)
      } catch (error) {
        console.error(error);
      }
    },
  }
}