<template>
  <svg :class="className" :viewBox="icon.viewBox" :width="size" :height="size" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }} </title>
    <use :xlink:href="icon.url" xmlns:xlink="http://www.w3.org/1999/xlink"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',

  props: {
    title: String,
    name: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      default: 24,
    },
    className: [String, Array]
  },

  computed: {
    icon() {
      let icon = require(`@/assets/icons/${this.size}/${this.name}.svg`)
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }

      return icon
    },
  }
}
</script>