export const CONFIG_CARS = {
  server: {
    host: process.env.VUE_APP_API_HOST,
    protocol: process.env.VUE_APP_API_PROTOCOL,
  },
  pathnamePrefix: 'api/',
  endpoints: {
    cars: {
      pathname: 'cars',
    },
    car: {
      pathname: 'cars/{car}',
    },
    delete: {
      pathname: 'cars/{car}/delete',
    },
    photoUpload: {
      pathname: 'cars/{car}/photo/upload',
    },
    photoDownload: {
      pathname: 'company/cars/{car}/photo/download',
    },
    photoProcess: {
      pathname: 'company/cars/{car}/photo/process',
    },
  },
}