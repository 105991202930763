export const CONFIG_PHOTOS = {
  server: {
    host: process.env.VUE_APP_API_HOST,
    protocol: process.env.VUE_APP_API_PROTOCOL,
  },
  pathnamePrefix: 'api/admin/',
  endpoints: {
    sections: {
      pathname: 'section',
    },
    section: {
      pathname: 'section/{sectionID}',
    },
    deleteSection: {
      pathname: 'section/{sectionID}/delete',
    },
    photoUpload: {
      pathname: 'photo/upload',
    },
    photoDownload: {
      pathname: 'photo/download',
    },
    markProcessed: {
      pathname: 'photo/mark-processed',
    },
    datasetDownload: {
      pathname: 'dataset/download',
    },
    datasetUpload: {
      pathname: 'dataset/upload',
    },
    datasetDownloadNeuro: {
      pathname: 'neurodataset/download/{sectionID}',
    },
    datasetUploadNeuro: {
      pathname: 'neurodataset/upload',
    },
  },
}