export const CONFIG_ADMIN_COMPANIES = {
  server: {
    host: process.env.VUE_APP_API_HOST,
    protocol: process.env.VUE_APP_API_PROTOCOL,
  },
  pathnamePrefix: 'api/',
  endpoints: {
    all: {
      pathname: 'admin/companies',
    },
    company: {
      pathname: 'admin/companies/{company}',
    },
    delete: {
      pathname: 'admin/companies/{company}/delete',
    },
    addCredits: {
      pathname: 'admin/companies/{company}/add-credits',
    },
    toggleBlock: {
      pathname: 'admin/companies/{company}/toggle-block',
    },
    settings: {
      pathname: 'admin/companies/{company}/settings',
    },
    users: {
      pathname: 'admin/companies/{company}/users',
    },
    user: {
      pathname: 'admin/users/{user}',
    },
    userToggleBlock: {
      pathname: 'admin/users/{user}/toggle-block',
    },
  },
}