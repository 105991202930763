import axios from 'axios'
import Url from 'url'
import cookies from 'js-cookie'
import getUrlWithParamsConfig from './getUrlWithParamsConfig'

const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(
  (error) => {
    return Promise.reject(error.data)
  },
)

import {
  CONFIG_AUTH,
  CONFIG_ADMIN_COMPANIES,
  CONFIG_CARS,
  CONFIG_COMPANY_USERS,
  CONFIG_SETTINGS,
  CONFIG_PHOTOS,
} from './endpoints'

const staticHeader = {
  json: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
  formData: {
    "Content-Type": "application/x-www-form-urlencoded",
  }
}

function processingBody(headerType, body) {
  switch (headerType) {
    case 'formData':
      const formData = new FormData()
      try {
        Object.entries(body).forEach(([key, value]) => {
          if (key === 'file') {
            value.forEach((item, index) => {
              console.log('item', item);
              return formData.append(key, item)
            })
          } else {
            console.log('value', value);
            formData.append(key, value)
          }
        })
      } catch (error) {
        console.log(error);
      }
      return formData
    default:
      return JSON.stringify(body)
  }
}

async function defaultRequest(method, config, endpoint, params, headerType) {
  let headers = {
    ...staticHeader[headerType || 'json'],
    "Authorization": 'Bearer ' + cookies.get('token'),
  };
  const { apiConfigUrl, body } = getUrlWithParamsConfig(method, config, endpoint, params)
  const options = {
    headers: headers,
  }
  switch (method) {
    case 'post':
    case 'put':
    case 'putch':
      return axios[method](Url.format(apiConfigUrl), processingBody(headerType, body), options)
    default:
      return axios[method](Url.format(apiConfigUrl), options)
  }
}

class req {
  auth(method, endpoint, params, headerType) {
    return defaultRequest(method, CONFIG_AUTH, endpoint, params, headerType)
  }
  adminCompanies(method, endpoint, params, headerType, formData) {
    return defaultRequest(method, CONFIG_ADMIN_COMPANIES, endpoint, params, headerType, formData)
  }
  cars(method, endpoint, params, headerType, formData) {
    return defaultRequest(method, CONFIG_CARS, endpoint, params, headerType, formData)
  }
  companyUsers(method, endpoint, params, headerType, formData) {
    return defaultRequest(method, CONFIG_COMPANY_USERS, endpoint, params, headerType, formData)
  }
  settings(method, endpoint, params, headerType, formData) {
    return defaultRequest(method, CONFIG_SETTINGS, endpoint, params, headerType, formData)
  }
  photos(method, endpoint, params, headerType, formData) {
    return defaultRequest(method, CONFIG_PHOTOS, endpoint, params, headerType, formData)
  }
}

export default new req()
