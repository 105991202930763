<template>
  <div :class="$style.core">
    <button :class="$style.toggle" class="flex align-items-center ml-4" @click="toggle">
      <div class="flex align-items-center">
        <img :src="items[$i18n.locale]?.icon" alt="" :class="$style.flag" class="mr-3 _img-base">
        <span v-t="items[$i18n.locale]?.label"></span>
        <Icon name="chevron_down" className="_icon-gray" />
      </div>
    </button>
    <OverlayPanel ref="panel">
      <button
        class="flex align-items-center px-3 py-2"
        v-for="(item, key) in items"
        :key="key"
        @click="() => {
          $i18n.locale = key
          panel.hide()
        }">
        <img :src="item.icon" alt="" :class="$style.flag" class="mr-3 _img-base">
        <span v-t="item.label"></span>
      </button>
    </OverlayPanel>
  </div>
</template>

<script>

import { ref } from 'vue';
import { Icon } from '../../components/ui'
import OverlayPanel  from 'primevue/overlaypanel';
import iconEn from '../../assets/img/flag/uk.svg'
import iconDe from '../../assets/img/flag/germany.svg'


  // $i18n.locale
export default {
  components: {
    Icon,
    OverlayPanel,
  },
  setup() {
    const items = {
      'en': {
        label: 'language.en',
        icon: iconEn,
      },
      'de': {
        label: 'language.de',
        icon: iconDe,
      },
    }
    const panel = ref();
    const toggle = (e) => {
      panel.value.toggle(e)
    };
    return { items, toggle, panel }
  }
}
</script>

<style lang="scss" module>
  .core {
    position: relative;
  }

  .drop {
    width: 100px;
    position: absolute;
    right: 0;
    top: 100%;
    background-color: #fff;
    z-index: 5;
  }
</style>