export const CONFIG_AUTH = {
  server: {
    host: process.env.VUE_APP_API_HOST,
    protocol: process.env.VUE_APP_API_PROTOCOL,
  },
  pathnamePrefix: 'api/',
  endpoints: {
    login: {
      pathname: 'auth/login',
    },
    me: {
      pathname: 'auth/me',
    },
    refresh: {
      pathname: 'auth/refresh',
    },
    logout: {
      pathname: 'auth/logout',
    },
    name: {
      pathname: 'auth/name/update',
    },
    email: {
      pathname: 'auth/email/update',
    },
    avatar: {
      pathname: 'auth/avatar/update',
    },
  },
}