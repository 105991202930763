export const CONFIG_SETTINGS = {
  server: {host: process.env.VUE_APP_API_HOST,
    protocol: process.env.VUE_APP_API_PROTOCOL,
  },
  pathnamePrefix: 'api/',
  endpoints: {
    presets: {
      pathname: 'profile/presets',
    },
  },
}