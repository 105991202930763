export const CONFIG_COMPANY_USERS = {
  server: {
    host: process.env.VUE_APP_API_HOST,
    protocol: process.env.VUE_APP_API_PROTOCOL,
  },
  pathnamePrefix: 'api/',
  endpoints: {
    companyProfile: {
      pathname: 'company/profile',
    },
    companyBackgrounds: {
      pathname: 'company/background',
    },
    companyBackground: {
      pathname: 'company/background/{background}',
    },
    companyLogos: {
      pathname: 'company/logo',
    },
    companyLogo: {
      pathname: 'company/logo/{logo}',
    },
    companyBanners: {
      pathname: 'company/banner',
    },
    companyBanner: {
      pathname: 'company/banner/{banner}',
    },
    users: {
      pathname: 'company/users',
    },
    user: {
      pathname: 'company/users/{user}',
    },
    toggleBlock: {
      pathname: 'company/users/{user}/toggle-block',
    },
    addCredits: {
      pathname: 'company/users/{user}/add-credits',
    },
  },
}