<template>
  <div :class="$style.core">
    <div :class="$style.sidebar">
      <Logo />
      <Menu />
    </div>
    <div :class="$style.body">
      <Header />
      <div class="_container pt-4 pb-4">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import cookies from 'js-cookie'
import { useStore } from 'vuex'
import { Logo, Menu, Header } from './components'

import API from '../api'

const getToken = async () => {
  if (!cookies.get('no-refresh') && cookies.get('token')) {
    try {
      const { data } = await API.auth('post', 'refresh')
      cookies.set('token', data.access_token, { expires: data.expires_in / 8.64e+7 })
      cookies.set('no-refresh', true, { expires: data.expires_in / 2 / 8.64e+7 })
    } catch (error) {
      console.error(error);
    }
  }
}

export default {
  components: {
    Logo,
    Menu,
    Header,
  },
  setup() {
    const store = useStore()
    store.commit('account/setAccount', JSON.parse(cookies.get('user') || '{}'))
    getToken()
    setInterval(() => {
      getToken()
    }, 10000);
  },
}
</script>

<style lang='scss' module>
  .core {
    display: flex;
    height: 100vh;
  }
  .sidebar {
    height: 100%;
    width: 256px;
    background-color: $primary_dark;
    background-image: url('@/../../assets/img/wave.svg');
    background-position: bottom;
    background-repeat: no-repeat;
  }
  .body {
    width: calc(100% - 256px);
    overflow-y: auto;
  }
</style>