export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "page": {
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Armaturenbrett"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])}
  },
  "language": {
    "en_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "de_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Germany"])},
    "pt_f": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En"])},
    "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
    "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pt"])}
  }
}