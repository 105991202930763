<template>
  <div :class="[$style.core, $style[size]]">
    <img :src="img" alt="" v-if="img" :class="$style.img">
    <Icon  name="user-32" :size="32" v-else />
  </div>
</template>

<script>

import { ref } from 'vue';
import { Icon } from './'
export default {
  components: {
    Icon,
  },
  props: {
    size: String,
    img: String,
  },
  setup() {

    return {}
  }
}
</script>

<style lang="scss" module>
  .core {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary_light_grey;
  }
  .medium {
    width: 46px;
    height: 46px;
  }
  .big {
    width: 82px;
    height: 82px;
  }
  .img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
  }
</style>